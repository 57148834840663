



































































import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'

import Confirmation from '@/components/modals/Confirmation.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import UsersMultipleSearch from '@/components/_uikit/search/UsersMultipleSearch.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerRolesModule from '@/store/modules/manager/roles'
import { RoleResource, UserShortResource } from '@/store/types'
import { GET_DEFAULT_TABLE_FILTER } from '@/store'
import { tableFooterOptions } from '@/utils/constants'

@Component({
  components: {
    Confirmation,
    TableFooter,
    TextInput,
    UsersMultipleSearch,
  },
})
export default class RolesItemUsers extends Mixins(NotifyMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private role!: RoleResource

  private footerOptions = tableFooterOptions
  private usersSelected: UserShortResource[] = []
  private roleUsers: UserShortResource[] = []
  private filterSearch = ''
  private filter = GET_DEFAULT_TABLE_FILTER()

  private get headers () {
    return [
      { cellClass: 'cell-icon cell-icon_first', text: '', value: 'data-table-select' },
      { text: 'Имя', value: 'name' },
      { text: 'Почта', value: 'email' },
    ]
  }

  private get users () {
    return ManagerRolesModule.users.filter(
      (user: UserShortResource) => `${user.name} ${user.surname}`.toLowerCase().indexOf(this.filterSearch.toLowerCase()) >= 0 || user.email?.toLowerCase().indexOf(this.filterSearch.toLowerCase()) >= 0,
    )
  }

  private created () {
    ManagerRolesModule.fetchRoleUsers(this.role.uuid)
      .catch((error) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.users.roles' })
      })
  }

  private handleAddUsers () {
    ManagerRolesModule.updateRoleUsers({
      action: 'increment',
      roleUUID: this.role.uuid,
      users: this.usersSelected,
    })
      .then(() => {
        this.$bus.$emit('add-users-to-group')
        this.notifySuccess('Пользователи добавлены')
      })
      .catch(this.notifyError)
  }

  private handleDeleteUsers () {
    ManagerRolesModule.updateRoleUsers({
      action: 'decrement',
      roleUUID: this.role.uuid,
      users: this.roleUsers,
    })
      .then(() => {
        this.roleUsers = []
        this.notifySuccess('Пользователи удалены')
      })
      .catch(this.notifyError)
  }

  private confirmDeleteUsers () {
    this.confirm.open(
      'Удаление пользователей из группы',
      `Вы уверены, что хотите удалить пользователей из группы "${this.role.title}"?`,
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(this.handleDeleteUsers)
      .catch(() => {return})
  }
}
